import { useOktaAuth } from '@okta/okta-react';
import _ from 'lodash';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import SnackbarFeedback from '../components/SnackbarFeedback';
import MapSecteurAgence from '../components/map/mapSecteurAgence';
import { AppConfigContext } from '../context';
import BackendApiService from '../service/BackendApiService';
import { buildSecteurAffecteEtab } from '../service/regions_foncia';

import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { useTheme } from '@mui/material/styles';
import ClearIcon from '@mui/icons-material/Clear';
import RemplacerManagerDialog from './RemplacerManagerDialog';
import { ADB, ADMIN, GESTIONNAIRE_REGION, DIRECTEUR_COMMERCIAL_TRANSACTION } from '../Constantes';

function SecteurAgence() {
  const appConfig = useContext(AppConfigContext);
  const { authState } = useOktaAuth();

  const theme = useTheme();

  const [selectedEtablissement, setSelectedEtablissement] = useState(null);
  const [copySelectedEtablissement, setCopySelectedEtablissement] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [metier, setMetier] = useState('gl');
  const [etablissements, setEtablissements] = useState([]);

  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [feedback, setFeedback] = useState(null);

  const [affectationMode, setAffectationMode] = useState(false);
  const [collabsSelectedEtablissement, setCollabsSelectedEtablissement] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [canUpdateManagerGL, setCanUpdateManagerGL] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [selectedAffectationZones, setSelectedAffectationZones] = useState([]);

  const mapRef = useRef();

  const service = useMemo(() => new BackendApiService(appConfig.app.backendApiUrl, authState), [appConfig.app.backendApiUrl, authState]);

  const handleSelectEtablissement = (selectedEtab) => {
    setCopySelectedEtablissement(_.cloneDeep(selectedEtab));
    setSelectedEtablissement(_.cloneDeep(selectedEtab));
  };

  const showEtablissementsByRegion = async function (nomRegion) {
    setLoading(true);

    try {
      const res = await service.fetchEtablissements(nomRegion);

      if (!res.ok) {
        const errorStatus = res.status;
        const errorText = await res.text();
        throw new Error(errorStatus + ' ' + errorText);
      }

      const data = await res.json();
      const filteredData = data.filter((etab) => etab.latitude && etab.longitude);

      setEtablissements(filteredData);
      mapRef.current.showEtablissements(filteredData);
    } catch (error) {
      handleOpenSnackbar({ severity: 'error', msg: 'Erreur survenue: ' + error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleSauvegarder = async function (e) {
    setLoading(true);

    try {
      const res = await service.saveAffectationsEtablissement(selectedEtablissement, metier, selectedAffectationZones);

      if (!res.ok) {
        const errorStatus = res.status;
        const errorText = await res.text();
        throw new Error(errorStatus + ' ' + errorText);
      }
      disableAffectationMode();

      await showEtablissementsByRegion(selectedRegion.nom);
      handleOpenSnackbar({ severity: 'success', msg: 'Sauvegarde OK' });
      setSelectedEtablissement(null);
      setSelectedAffectationZones([]);
    } catch (error) {
      setFeedback({ severity: 'error', msg: 'Erreur survenue: ' + error.message });
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  const disableAffectationMode = function (e) {
    setAffectationMode(false);
    mapRef.current.disableAffectationMode(etablissements);
  };

  const defineSecteurs = function (metier) {
    setAffectationMode(true);
    setMetier(metier);

    let allSecteurEtab = buildSecteurAffecteEtab(etablissements, metier);

    mapRef.current.enableAffectationMode(allSecteurEtab, metier);
  };

  const handleSelectRegion = function (region) {
    setLoading(true);
    setSelectedRegion(region);

    showEtablissementsByRegion(region.nom);
  };

  const onSelectedCommune = (zone, codeCommune) => {
    const found = selectedAffectationZones.find((item) => item.code === codeCommune);
    if (!found) {
      setSelectedAffectationZones([...selectedAffectationZones, zone]);
    } else {
      const newAffectationsZones = selectedAffectationZones.filter((item) => item.code !== codeCommune);
      setSelectedAffectationZones(newAffectationsZones);
    }
  };

  const handleSelectCommune = function (commune) {
    let secteurs = [...selectedEtablissement[metier], { ...commune }];
    onSelectedCommune(commune, commune.code);
    setSelectedEtablissement({ ...selectedEtablissement, [metier]: secteurs });
  };

  const handleRemoveCommune = function (codeCommune) {
    let secteurs = selectedEtablissement[metier];

    let affExistanteArr = _.remove(secteurs, {
      code: codeCommune,
    });

    if (affExistanteArr.length > 0) {
      const aff = affExistanteArr[0];
      onSelectedCommune(aff, codeCommune);
    }
  };

  const handleCloseSnackbar = function () {
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = function (feedback) {
    setFeedback(feedback);
    setOpenSnackbar(true);
  };

  const showRegion = function () {
    mapRef.current.showRegion();
  };

  const showFrance = function () {
    setSelectedRegion(null);
    setSelectedEtablissement(null);
    setAffectationMode(false);

    mapRef.current.showFrance();
  };

  const handleClickOpenDialog = async () => {
    let response;
    try {
      setLoading(true);
      response = await service.fetchCollabs(selectedEtablissement.refSiFinance);
      const collabsOfAgenceReponse = await response.json();
      const collabsOfAgence = _.uniqBy(collabsOfAgenceReponse, 'idIndividu');
      const remplacants = collabsOfAgence.filter((collab) => collab.idIndividu !== selectedEtablissement.managerGL?.idIndividu);
      setCollabsSelectedEtablissement(remplacants);
      if (remplacants.length === 0)
        return handleOpenSnackbar({ severity: 'warning', msg: "Aucun autre collaborateur n'est rattaché à cette agence" });
      else setOpenDialog(true);
    } catch (error) {
      console.log(error);
      if (response.status === 401) {
        handleOpenSnackbar({ severity: 'error', msg: 'Opération non autorisée' });
      } else if (response.status === 403) {
        handleOpenSnackbar({ severity: 'error', msg: "Vous n'avez pas le droit pour effectuer cette opération" });
      } else if (response.status === 404) {
        handleOpenSnackbar({ severity: 'error', msg: "Aucun collaborateur n'est rattaché à cette agence" });
      } else {
        handleOpenSnackbar({ severity: 'error', msg: 'Erreur survenue: ' + error.message });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = async (newManagerGL) => {
    setOpenDialog(false);
    disableAffectationMode();
    setCollabsSelectedEtablissement(null);
    if (newManagerGL) {
      setLoading(true);
      try {
        const response = await service.saveManagerGL(selectedEtablissement.refSiFinance, newManagerGL.idIndividu);
        if (!response.ok) throw new Error(response.status);

        const newSelectedEtab = {
          ...selectedEtablissement,
          managerGL: newManagerGL,
        };
        setSelectedEtablissement(newSelectedEtab);
        const newEtablissementsList = etablissements.map((etablissement) =>
          etablissement.refSiFinance === newSelectedEtab.refSiFinance ? newSelectedEtab : etablissement
        );
        setEtablissements(newEtablissementsList);
        mapRef.current.showEtablissements(newEtablissementsList);
        handleOpenSnackbar({ severity: 'success', msg: 'Affectation du manager GL effectuée avec succès' });
      } catch (error) {
        console.log(error);
        handleOpenSnackbar({ severity: 'error', msg: "Erreur lors de l'affectation du manager" });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRetrunButton = async () => {
    setSelectedEtablissement(_.cloneDeep(copySelectedEtablissement));
    disableAffectationMode();
  };

  const handleRemoveManagerGL = async () => {
    setLoading(true);
    try {
      const response = await service.removeManagerGL(selectedEtablissement.refSiFinance);
      if (!response.ok) throw new Error(response.status);
      const newSelectedEtab = { ...selectedEtablissement, managerGL: null };
      setSelectedEtablissement(newSelectedEtab);
      const newEtablissementsList = etablissements.map((etablissement) =>
        etablissement.refSiFinance === newSelectedEtab.refSiFinance ? newSelectedEtab : etablissement
      );
      setEtablissements(newEtablissementsList);
      mapRef.current.showEtablissements(newEtablissementsList);
      handleOpenSnackbar({ severity: 'success', msg: 'Le manager GL a été retiré avec succès' });
    } catch (error) {
      console.log(error);
      handleOpenSnackbar({ severity: 'error', msg: 'Erreur lors de la suppression du manager GL' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      setLoading(true);
      let response;
      try {
        response = await service.fetchUserInfo();
        const userInfos = await response.json();
        setUserInfo(userInfos);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchUserInfo();
  }, [service]);

  useEffect(() => {
    const profil = userInfo?.profil;
    //const refCabinetAccess = userInfo?.access.reduce((acc, val) => [...acc, val.refCabinet], []);
    const refCabinetAccess = [...new Set(userInfo?.access.reduce((acc, val) => [...acc, val.refCabinet], []))];
    const isAuthorizedToUpdateManagerGL =
      profil === ADMIN || ((profil === GESTIONNAIRE_REGION || DIRECTEUR_COMMERCIAL_TRANSACTION) && refCabinetAccess?.includes(selectedEtablissement?.refCabinet));
    setCanUpdateManagerGL(isAuthorizedToUpdateManagerGL);
  }, [selectedEtablissement, userInfo]);
  if (!authState || !authState.isAuthenticated) return null;

  return (
    <div className="map-wrap">
      {selectedRegion?.nom && (
        <Card sx={{ minWidth: 250, maxWidth: 400, position: 'absolute', zIndex: 1000, top: '10px', left: '10px' }}>
          <CardContent>
            {loading && (
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Chargement... <CircularProgress size={20} />
              </Typography>
            )}

            {!loading && (
              <>
                <Breadcrumbs aria-label="breadcrumb">
                  <Link underline="hover" color="inherit" href="#" onClick={showFrance}>
                    France
                  </Link>
                  <Link underline="hover" color="text.primary" onClick={showRegion} href="#">
                    {selectedRegion.nom}
                  </Link>
                </Breadcrumbs>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {!loading ? etablissements.length + ' agences' : ''}
                </Typography>
              </>
            )}

            <Typography variant="h5" component="div">
              {selectedEtablissement?.nomUsage}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {selectedEtablissement?.denominationSociale}
            </Typography>
            <Typography sx={{ mb: 1.5 }} variant="body2">
              {selectedEtablissement?.refSiFinance}
              <br />
              {selectedEtablissement?.adresseLigne1}
              <br />
              {selectedEtablissement?.codePostal} {selectedEtablissement?.ville}
            </Typography>
            <Typography sx={{ mb: 2 }} variant="body2">
              {selectedEtablissement && <strong> Manager ADB : </strong>}{' '}
              {selectedEtablissement ? (
                canUpdateManagerGL ? (
                  selectedEtablissement.managerGL ? (
                    <>
                      <Link onClick={handleClickOpenDialog} href="#">
                        {selectedEtablissement?.managerGL.nom} {selectedEtablissement?.managerGL.prenom}
                      </Link>
                      <ClearIcon onClick={handleRemoveManagerGL} style={{ color: 'red', verticalAlign: 'middle', marginLeft: '0.5em' }} />
                    </>
                  ) : (
                    <Link onClick={handleClickOpenDialog} href="#">
                      + Ajouter
                    </Link>
                  )
                ) : selectedEtablissement.managerGL ? (
                  <Typography sx={{ mb: 1.5 }} variant="body2" component="span">
                    {selectedEtablissement?.managerGL.nom} {selectedEtablissement?.managerGL.prenom}
                  </Typography>
                ) : (
                  <Typography sx={{ mb: 1.5 }} variant="body2" component="span">
                    Non nommé
                  </Typography>
                )
              ) : (
                ''
              )}
            </Typography>
            {affectationMode && (
              <Paper sx={{ mb: 2, width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: '300px' }}>
                  <Table size="small" stickyHeader>
                    {_.isEmpty(selectedEtablissement[metier]) && <caption>Aucun secteur affecté</caption>}
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ backgroundColor: theme.palette[metier].main }}>Secteurs {metier.toUpperCase()}</TableCell>
                        <TableCell sx={{ backgroundColor: theme.palette[metier].main }} align="right">
                          Code insee
                        </TableCell>
                        <TableCell sx={{ backgroundColor: theme.palette[metier].main }} align="right">
                          Code postal
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedEtablissement[metier].sort((a, b) => a.nom.localeCompare(b.nom)).map((c) => (
                        <TableRow key={c.code} sx={{ '&:nth-of-type(odd)': { backgroundColor: theme.palette.action.hover } }}>
                          <TableCell component="th" scope="row">
                            {c.nom}
                          </TableCell>
                          <TableCell align="right">{c.code}</TableCell>
                          <TableCell align="right">{c.codesPostaux.join(',')}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}

            {!loading && selectedEtablissement && !affectationMode && (
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                <Button size="small" variant="contained" color="gl" onClick={(e) => defineSecteurs('gl')}>
                  Secteurs GL
                </Button>
                <Button size="small" variant="contained" color="copro" onClick={(e) => defineSecteurs('copro')}>
                  Secteurs COPRO
                </Button>
              </Stack>
            )}

            {!loading && selectedEtablissement && affectationMode && (
              <Stack direction="row" justifyContent="space-between" spacing={2}>
                {selectedEtablissement.secteurAffectable && (
                  <Button size="small" variant="contained" color={metier} onClick={handleSauvegarder}>
                    {' '}
                    Sauvegarder
                  </Button>
                )}
                <Button size="small" variant="contained" color={metier} onClick={handleRetrunButton}>
                  {selectedEtablissement.secteurAffectable ? 'Annuler' : 'Retour'}
                </Button>
              </Stack>
            )}
          </CardContent>
        </Card>
      )}

      <MapSecteurAgence
        ref={mapRef}
        osmUrls={appConfig.app.osmUrls}
        selectedRegion={selectedRegion}
        selectedEtablissement={selectedEtablissement}
        handleSelectRegion={handleSelectRegion}
        handleSelectEtablissement={handleSelectEtablissement}
        handleSelectCommune={handleSelectCommune}
        handleRemoveCommune={handleRemoveCommune}
        handleOpenSnackbar={handleOpenSnackbar}
        theme={theme}
      ></MapSecteurAgence>

      <SnackbarFeedback openSnackbar={openSnackbar} handleCloseSnackbar={handleCloseSnackbar} feedback={feedback} />

      {!loading && selectedEtablissement && collabsSelectedEtablissement && canUpdateManagerGL && (
        <RemplacerManagerDialog open={openDialog} onClose={handleCloseDialog} collabsEtablissement={collabsSelectedEtablissement} source={ADB} />
      )}
    </div>
  );
}

export default SecteurAgence;
